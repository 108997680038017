export const LARGE_PASSENGER_VEHICLE_IDENTIFIER = '+A';
export const SSD_PROGRAM_TYPE = 'SUB_SAME_DAY';
export const DSP_PROVIDER_TYPE = 'DA/DSP';
export const FLEX_PROVIDER_TYPE = 'Flex';
export const FLEX_INSTANT_PROVIDER_TYPE = 'IO Flex';
export const SSD_PILOT_SITES_ALLOWED_USERS = [
  'tariqxm',
  'rbhanson',
  'akhilc',
  'someshdb',
  'yanademe',
  'kitavm',
  'esmmicha',
];
