import {
  FilterOption,
  PREFIX_COMPANY_NAME,
  PREFIX_PROVIDER_TYPE,
} from '../../util/FilterConstants';
import DataFilterController from '../../controllers/DataFilterController';
import FilterHelper from '../../util/FilterHelper';
import React from 'react';
import Row from '@amzn/meridian/row';
import Select, { SelectOption } from '@amzn/meridian/select';
import TransporterHelper from '../../util/TransporterHelper';
import filterReactor from '../../reactors/filterReactor';
import filterStore from '../../stores/filterStore';
import transporterStore from '../../stores/transporterStore';

export interface TransporterFilterProps {}

export interface TransporterFilterState {
  value: string[];
}

export default class TransporterFilterComp extends React.Component<
  TransporterFilterProps,
  TransporterFilterState
> {
  componentDidMount() {
    this.setState({ value: [] });

    filterReactor.selectedTransporterFilterCodesChange$.subscribe(
      async (event) => {
        const { selectedTransporterFilterCodes } = filterStore;
        this.setState({ value: selectedTransporterFilterCodes });
      }
    );
  }

  constructor(props: TransporterFilterProps) {
    super(props);
  }
  render() {
    const filterOptions: FilterOption[] = [];
    const { transporters } = transporterStore;

    // Company name filter
    const companyNames =
      TransporterHelper.getUniqueTransporterCompanyNames(transporters);
    companyNames.forEach((companyName) => {
      filterOptions.push(this.makeCompanyNameOption(companyName));
    });

    // Provider type filter
    const providerTypes =
      TransporterHelper.getUniqueTransporterProviderTypes(transporters);
    providerTypes.forEach((providerType) => {
      filterOptions.push(this.makeProviderTypeOption(providerType));
    });

    const selectOptions = filterOptions.map((option) => (
      <SelectOption
        key={`transporterFilterOption${option.code}`}
        label={option.code}
        value={option.code}
      />
    ));

    return (
      <Row spacingInset="100">
        <Select
          label="Filter by"
          value={this.state?.value}
          onChange={(newValues) => this.handleValueChange(newValues)}
          width="100%"
        >
          {selectOptions}
        </Select>
      </Row>
    );
  }

  private handleValueChange(newValues: string[]) {
    this.setState({ value: newValues });
    DataFilterController.handleTransporterFilterValueChange(newValues);
  }

  private makeCompanyNameOption(companyName: string): FilterOption {
    return {
      code: FilterHelper.makeFilterString(PREFIX_COMPANY_NAME, companyName),
    };
  }

  private makeProviderTypeOption(providerType: string): FilterOption {
    return {
      code: FilterHelper.makeFilterString(PREFIX_PROVIDER_TYPE, providerType),
    };
  }
}
